import { Component, Input } from '@angular/core';
import { GroupType, ItemGroup } from '../dropdown-model';

@Component({
  selector: 'sho-item-group-shared',
  templateUrl: './item-group.component.html',
  styleUrls: ['./item-group.component.css']
})
export class ItemGroupComponent {
  arrowUp = false;
  @Input() itemGroup?:ItemGroup;

  formatItem(item:string | undefined ):string{
    return item === undefined ? '' : item.toLowerCase().replace(" ","_");
  }

  isItemCheckType (type:GroupType|undefined) :boolean {
    return GroupType.Check == type;
  }
}
