export interface ModalSettings {
  tittle?:string;
  paragraph?:string;
  paragraphSize?:string;
  icon?:ModalIcon;
}

export enum ModalIcon {
  Success,
  Error
}
