import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { ProductModel } from '@core/domain/product.model';
import { RequestStatus } from '@shared/interfaces/request-status.model';
import { ToastService } from '@shared/services/toast.service';

interface InputData {
  product: ProductModel;
}

interface OutputData {
  rta: boolean;
}

@Component({
  selector: 'sho-modal-confirm-star',
  templateUrl: './modal-confirm-star.component.html',
  styleUrls: ['./modal-confirm-star.component.css']
})
export class ModalConfirmStarComponent {
  status: RequestStatus = 'init';
  product: ProductModel;

  constructor(
    private dialogRef: DialogRef<OutputData>,
    @Inject(DIALOG_DATA) data: InputData,
    private _modal: ToastService,
    ) {
      this.product = data.product;

  }
  close() {
    this.dialogRef.close();
  }

  async realizarPago(){
    this.status = 'loading';
    this.close();
    this._modal.show(
      'Productos han sido asignados con éxito',
      '',
      "SUCCESS", 5
  )

  }

}
