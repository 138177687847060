import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject, map, Observable, of, ReplaySubject, take} from "rxjs";
import {SessionModel} from "@shared/interfaces/session.model";
import {environment} from "@environments/environment";
import {RoleType} from "@shared/enum/role.enum";

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  #sessionStatus = new BehaviorSubject<SessionModel>({})

  public sessionStatus$ = this.#sessionStatus.asObservable()

  public setSessionStatus(sessionModel: SessionModel): void {
    const {shopId, role} = sessionModel
    this._setShopIdStore(shopId ?? '')
    this._setRoleStore(role ?? 'ADMIN')
    this.#sessionStatus.next(sessionModel)
  }

  public setSessionShopId(shopId: string): void {
    localStorage.setItem('shop', shopId)
    this._setShopIdStore(shopId)
    this.#sessionStatus.next({shopId})
  }

  public getSessionLocalStoreShopId(): string | null {
    return localStorage.getItem('shop')
  }

  public setSessionRole(role: RoleType): void {
    this._setRoleStore(role)
    this.#sessionStatus.next({role})
  }

  public getEmail(): Observable<string> {
    return this.#sessionStatus.pipe(map(this._email))
  }

  public getRole(): Observable<string> {
    // console.log('this._role',this._role)
    // return this._role ? this.#sessionStatus.pipe(map(this._role)): of(this.getRoleStore())
    return of(this.getRoleStore())
  }

  public getShopId(): Observable<string> {
    return this.#sessionStatus.pipe(map(this._shopId))
  }

  public getShopIdStore(): string {
    return sessionStorage.getItem('shop') ?? ''
  }

  public getRoleStore(): string {
    return sessionStorage.getItem('role') ?? ''
  }

  public getUserName(): Observable<string> {
    return this.#sessionStatus.pipe(map(this._userName))
  }

  public getUrlBaseShopId() {
    return this.getShopId().pipe(map((shopId) => `${environment.baseURL}${environment.endpoints.shop}/${shopId}`)
    )
  }

  public getUrlBaseShopIdMultivende() {
    return this.getShopId().pipe(map((shopId) => `${environment.baseUrlMultivende}${environment.endpoints.shop}/${shopId}`)
    )
  }

  private _email({email}: SessionModel): string {
    return email ?? ''
  }

  private _role({role}: SessionModel): string {
    return role ?? ''
  }

  private _shopId({shopId}: SessionModel): string {
    return shopId ?? ''
  }

  private _userName({userName}: SessionModel): string {
    return userName ?? ''
  }

  private _setShopIdStore(shopId: string) {
    sessionStorage.setItem('shop', shopId)
  }

  private _setRoleStore(role: RoleType) {
    sessionStorage.setItem('role', role)
  }

}
