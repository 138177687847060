import { Component } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { IconDefinition, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { ModalIcon, ModalSettings } from './modalAttributes.model';


const ELEMENT_DATA:any = [];

@Component({
  selector: 'sho-modal-attributes-ecommerce',
  templateUrl: './modal-attributes-ecommerce.component.html',
  styleUrls: ['./modal-attributes-ecommerce.component.scss']
})
export class ModalAttributesEcommerceComponent {
  displayedColumns: string[] = ["name", "value"];
  dataSource = ELEMENT_DATA;

  faCircleIcon:IconDefinition;
  settings:ModalSettings={};
  dataKeys: any

  constructor( private dialogRef: DialogRef ) {
    this.settings = dialogRef.config.data;
    this.settings.paragraphSize =  this.settings.paragraphSize ? this.settings.paragraphSize :"w-[350px]";
    this.faCircleIcon = this.settings.icon == ModalIcon.Success ? faCircleCheck : faCircleXmark;

    this.dataSource = this.settings.paragraph;

  }

  isSuccessColor ():boolean{
    return this.settings.icon == ModalIcon.Success;
  }

  //metodo para cerrar el modal.
  closed() {
    this.dialogRef.close();
  }

}
