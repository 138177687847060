<div class="flex items-center justify-between cursor-pointer" (click)="arrowUp = !arrowUp">
    <p class=" text-[#122B36] text-base font-bold">{{itemGroup?.name}}</p>
    <img *ngIf="arrowUp" class="w-[20px] h-[20px] mr-3" src="../../../../../../../../assets/svg/icon-row-up.svg">
    <img *ngIf="!arrowUp" class="w-[20px] h-[20px] mr-3" src="../../../../../../../../assets/svg/icon-row-down.svg">
</div>
<ng-container *ngIf="isItemCheckType(itemGroup?.type)">
    <div *ngIf="!arrowUp" class="flex flex-col ml-2">
        <ng-container *ngFor="let item of itemGroup?.items">
            <div class="flex items-center mt-2">
                <input type="checkbox" name="{{formatItem(item)}}" value="{{formatItem(item)}}">
                <label class="ml-2 text-[#122B36;] text-base font-medium" for="{{formatItem(item)}}">{{item}}</label>
            </div>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="!isItemCheckType(itemGroup?.type)">
    <div *ngIf="!arrowUp" class="flex flex-col ml-2">
        <ng-container *ngFor="let item of itemGroup?.items">           
            <div class="flex items-center mt-2">
                <input type="radio" name="{{formatItem(itemGroup?.name)}}" value="{{formatItem(item)}}">
                <label class="ml-2 text-[#122B36;] text-base font-medium" for="{{formatItem(item)}}">{{item}}</label>
            </div>
        </ng-container>
    </div>
</ng-container>
