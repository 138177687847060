<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 rounded-lg">
  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
     <!--  <mat-checkbox (change)="$event ? toggleAllRows() : null"
                    [color]="'primary'"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()">
      </mat-checkbox> -->
    </th>
    <td mat-cell *matCellDef="let row">
      <!-- <mat-checkbox (click)="$event.stopPropagation()"
                    [color]="'primary'"
                    (change)="$event ? toggleSubproducts(row) : null"
                    [checked]="allSelectedTree(row)"
                    [indeterminate]="indeterminateTree(row)"
                    [aria-label]="checkboxLabel(row)">
      </mat-checkbox> -->
    </td>
  </ng-container>

  <!-- Position Column -->
  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef class="mat-header-cell-centrado titulos font-semibold text-base leading-[22px] text-[#122B36]"><span class="titulos font-semibold text-base leading-[22px] text-[#122B36]">No</span></th>
    <td mat-cell *matCellDef="let data" class="text-center">
    <div *ngIf="data.position" class="titulos font-normal text-base leading-[19.2px] text-[#122B36]">{{data.position}}</div>
    <div *ngIf="!data.position">-</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>
      <span [style.paddingLeft.px]="52" class="titulos font-semibold text-base leading-[22px] text-[#122B36]">Nombre/SKU</span>
    </th>
    <td mat-cell *matCellDef="let data">
      <div class="flex items-center">
        <button mat-icon-button
              [style.visibility]="!data.expandable ? 'hidden' : ''"
              [style.marginLeft.px]="data.level * 32"
              (click)="treeControl.toggle(data)">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(data) ? 'chevron_right' : 'expand_more'}}
        </mat-icon>
      </button>
      <p class="titulos font-normal text-base leading-[19.2px] text-[#122B36] text-center">
        {{data.nombre}}
      </p>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef class="mat-header-cell-centrado titulos font-semibold text-base leading-[22px] text-[#122B36]"><span class="titulos font-semibold text-base leading-[22px] text-[#122B36]">Disponible</span></th>
    <td mat-cell *matCellDef="let data">
      <div *ngIf="!data.isDisponible" class="titulos font-normal text-base leading-[19.2px] text-[#122B36] flex justify-center">
        <img class="w-6 h-6" src="../../../../assets/svg/Alert.svg" alt="">
      </div>
      <div *ngIf="data.isDisponible" class="titulos font-normal text-base leading-[19.2px] text-[#122B36] flex justify-center">
        <img class="w-6 h-6" src="../../../../assets/svg/Checkmark.svg" alt="">
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="categoria">
    <th mat-header-cell *matHeaderCellDef class="mat-header-cell-centrado titulos font-semibold text-base leading-[22px] text-[#122B36]"><span class="titulos font-semibold text-base leading-[22px] text-[#122B36]">Categoría Tienda</span></th>
    <td mat-cell *matCellDef="let data">
      <div *ngIf="data.categoriaTienda.nombre" class="titulos font-normal text-base leading-[19.2px] text-[#122B36] text-center">{{data.categoriaTienda.nombre}}</div>
      <div *ngIf="!data.categoriaTienda.nombre">-</div>
    </td>
  </ng-container>

  <!-- <ng-container matColumnDef="Valor">
    <th mat-header-cell *matHeaderCellDef class="mat-header-cell-centrado titulos font-semibold text-base leading-[22px] text-[#122B36]"><span class="titulos font-semibold text-base leading-[22px] text-[#122B36]">Valor</span></th>
    <td mat-cell *matCellDef="let data">
      <div *ngIf="data.valor" class="titulos font-normal text-base leading-[19.2px] text-[#122B36]">{{data.valor}}</div>
      <div *ngIf="!data.valor">-</div>
    </td>
  </ng-container> -->

  <ng-container matColumnDef="Variantes">
    <th mat-header-cell *matHeaderCellDef class="mat-header-cell-centrado titulos font-semibold text-base leading-[22px] text-[#122B36]"><span class="titulos font-semibold text-base leading-[22px] text-[#122B36]">Variantes</span></th>
    <td mat-cell *matCellDef="let data">
      <div *ngIf="data.cantidadVariantes" class="titulos font-normal text-base leading-[19.2px] text-[#122B36] text-center">{{data.cantidadVariantes}}</div>
      <div *ngIf="!data.cantidadVariantes">-</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="stock">
    <th mat-header-cell *matHeaderCellDef class="mat-header-cell-centrado"><span class="titulos font-semibold text-base leading-[22px] text-[#122B36]">Stock</span></th>
    <td mat-cell *matCellDef="let data">
      <div *ngIf="data.cantidad" class="titulos font-normal text-base leading-[19.2px] text-[#122B36] text-center">{{ data.cantidad }}</div>
      <div *ngIf="!data.cantidad">-</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="precio">
    <th mat-header-cell *matHeaderCellDef class="mat-header-cell-centrado"><span class="titulos font-semibold text-base leading-[22px] text-[#122B36]">Precio</span></th>
    <td mat-cell *matCellDef="let data">
      <div *ngIf="data.precio" class="titulos font-normal text-base leading-[19.2px] text-[#122B36] text-center">{{data.precio | currency:'CLP':'symbol':''}}</div>
      <div *ngIf="!data.precio" class="text-center">-</div>
    </td>
  </ng-container>


  <ng-container matColumnDef="Atributos">
    <th mat-header-cell *matHeaderCellDef class="mat-header-cell-centrado"><span class="titulos font-semibold text-base leading-[22px] text-[#122B36]">Acciones</span></th>
    <td mat-cell *matCellDef="let data" class="titulos font-normal text-base leading-[19.2px] text-[#122B36]">
      <div class="text-center">
        <button mat-icon-button [matMenuTriggerFor]="accionesMenu" (menuOpened)="menuAbiertos(data)">
          <mat-icon>{{ data.menuAbierto ? 'close' : 'menu' }}</mat-icon>
        </button>
      </div>
      <!-- Define el menú -->
      <mat-menu #accionesMenu="matMenu" (closed)="menuCerrados(data)">
        <div *ngIf="data.productoPadre" (click)="agregarVariante(data)" mat-menu-item style="border-bottom: 1px solid #122B3633!important;">
          <div class="flex">
            <img class="w-[22px] h-[22px]" src="../../../../../assets/svg/AddBlack.svg" alt="">
            <button class="font-medium text-base leading-[22px] h-[22px] cursor-pointer text-[#122B36]">Agregar variante</button>
          </div>
        </div>
          <div (click)="editar(data)" mat-menu-item style="border-bottom: 1px solid #122B3633!important;">
            <div class="flex">
              <img class="w-[22px] h-[22px]" src="../../../../../assets/svg/Edit.svg" alt="">
              <button class="font-medium text-base leading-[22px] h-[22px] cursor-pointer text-[#122B36]">Editar</button>
            </div>
          </div>
          <!-- <div mat-menu-item (click)="eliminar(data)">
            <div class="flex">
              <img class="w-[22px] h-[22px]" src="../../../../../assets/svg/Trash.svg" alt="">
              <button class="font-medium text-base leading-[22px] h-[22px] cursor-pointer text-[#E41D2C]">Eliminar</button>
            </div>
          </div> -->
      </mat-menu>
    </td>




  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>


