import {inject, Injectable} from '@angular/core';
import {switchMap} from "rxjs/operators";
import {Role} from "@shared/types/role.type";
import {AuthService} from "@shared/services/auth.service";
import firebase from "firebase/compat";
import IdTokenResult = firebase.auth.IdTokenResult;
import {map, tap} from "rxjs";
import {SessionService} from "@shared/services/session.service";

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private readonly _auth = inject(AuthService);
  private readonly _sessionService = inject(SessionService)

  constructor() { }

  get role() {
    return this._auth.user.pipe(
      switchMap((user) => user.getIdTokenResult()),
      tap((elementoToken) => console.log({elementoToken})),
      map<IdTokenResult, Role>((result) => result.claims['role'])
    );
  }

  hasRole(roles: Role[]) {
    return this._sessionService.getRole().pipe(
      map((role) => roles.includes(role as Role))
    );
  }
}
