<div class="bg-white rounded-xl h-[390px]">
  <div class="flex flex-col justify-center items-center h-full p-5">
      <h1 class="text-[#122B36;] text-center text-lg font-bold w-[400px] leading-5">
          {{settings.tittle}}
      </h1>
      <mat-table [dataSource]="dataSource" class="mat-elevation-z8 mt-6 mb-6 rounded-xl">

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef class="flex justify-center"> Atributo </mat-header-cell>
          <mat-cell *matCellDef="let element" class="flex justify-center"> {{element.name}} </mat-cell>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="value">
          <mat-header-cell *matHeaderCellDef class="flex justify-center"> Valor </mat-header-cell>
          <mat-cell *matCellDef="let element" class="flex justify-center"> {{element.value}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>


      <app-btn-perfil class=" w-[153px]"
              [styles]="{height:'50px', background: '#146D81'}" (click)="closed()"
              color="success">
          Aceptar
      </app-btn-perfil>
  </div>
</div>
