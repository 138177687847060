import {Component, inject, OnInit} from '@angular/core';
import {NavigationEnd, Router, UrlSegment} from "@angular/router";
import {filter} from "rxjs";
import {Auth} from "@angular/fire/auth";
import {SessionService} from "@shared/services/session.service";
import {environment} from "@environments/environment";
import {FirebaseService} from "@shared/services/firebase.service";
import {initFlowbite} from "flowbite";
import {ShopService} from "@shared/services/shop.service";
import {ROUTES_APP} from "./app.routes";


declare global {
  interface Window {
    onloadTurnstileCallback: () => void;
    turnstile: {
      render: (idOrContainer: string | HTMLElement, options: any) => string;
      reset: (widgetIdOrContainer: string | HTMLElement) => void;
      getResponse: (widgetIdOrContainer: string | HTMLElement) => string | undefined;
      remove: (widgetIdOrContainer: string | HTMLElement) => void;
    };
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'seller-center-angular-frontend';

  private _auth = inject(Auth)
  private _firebase = inject(FirebaseService)
  private _router = inject(Router)
  private _sessionService = inject(SessionService)
  private _shopService = inject(ShopService)

  ngOnInit(): void {
    initFlowbite()
    this._updateShopId()
  }

  private _updateShopId() {
    const routesList = [
      ROUTES_APP.PRODUCT,
      ROUTES_APP.OFERTAS,
      ROUTES_APP.ESTADISTICAS,
      ROUTES_APP.MENSAJES,
      ROUTES_APP.ORDERS,
      ROUTES_APP.RETURNS,
      ROUTES_APP.PENDING,
      ROUTES_APP.PROFILE,
      ROUTES_APP.DASHBOARD,
    ]
    this._router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(async () => {
          if (this._auth.currentUser) {
            const navigation = this._router.getCurrentNavigation()?.finalUrl?.root.children['primary'].segments ?? []
            const valueShop = navigation.find(c => c.path === environment.endpoints.shop)
            if (valueShop instanceof UrlSegment) {
              const positionShop = navigation?.indexOf(valueShop)
              const shopId = navigation[positionShop + 1]
              if (routesList.includes(shopId.path as ROUTES_APP)) {
                await this._firebase.doLogout();
                this._router.navigateByUrl(ROUTES_APP.SIGNIN).then()
              } else if (shopId) {
                this._sessionService.setSessionShopId(shopId.path)
              }
            }
          } else {
            /*
            this._shopService.getShops(true)
              .subscribe(res => {
                this._sessionService.setSessionShopId(res[0].id)
              }) */
          }
        }
      )
  }

}
