<app-logo-navbar-soho></app-logo-navbar-soho>
<div class="flex flex-col justify-center h-full items-center w-full">
  <img
    src="./../../../assets/svg/404.svg"
    class="p-[52px] h-full lg:w-[55%]"
    alt="soho Logo not found"
  />
  <p
    class="font-bold text-2xl leading-[22px] text-[#000000] text-center lg:mt-[-138px]"
  >
    Esta página no existe
  </p>

  <div
    class="cursor-pointer mt-[19px] flex items-center justify-center w-[345px] h-[50px] bg-[#146D81] text-base rounded-[7px] text-white"
    (click)="goBack()"
  >
    <p class="text-[#FFFFFF] font-bold leading-[22px] text-base">Volver</p>
  </div>
</div>
