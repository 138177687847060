export interface ModalSettings {
    tittle?:string;
    paragraph?:string;
    paragraphSize?:string;
    icon?:ModalIcon;
    idProduct?:string;
    imagen?:string;
    video?:string;
}

export enum ModalIcon {
    Success,
    Error,
    Warning
}
