export enum GroupType {
    Check, Radio
}

export enum IconDropdown {
    Filter,
    Order
}

export interface ItemGroup {
    name:string;
    type:GroupType;
    items:string[];
}
