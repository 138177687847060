import {Component, Input} from '@angular/core';

@Component({
  selector: 'sho-spinner-ecommerce',
  templateUrl: './spinner-ecommerce.component.html',
  styleUrls: ['./spinner-ecommerce.component.scss']
})
export class SpinnerEcommerceComponent {
  @Input() text: string = 'Loading...'
}
