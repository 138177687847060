import {HttpClient} from "@angular/common/http";
import {inject, Injectable} from "@angular/core";
import {
  Auth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInAnonymously,
  signInWithEmailAndPassword,
  signOut,
  User,
  UserCredential
} from "@angular/fire/auth";
import {doc, docData, DocumentData, DocumentReference, Firestore, getDoc, getFirestore, onSnapshot} from "@angular/fire/firestore";
import {catchError, Observable, tap, throwError} from "rxjs";

import {environment} from "src/environments/environment";
import jwt_decode from "jwt-decode";
import {SessionService} from "@shared/services/session.service";
import {BucketStatus} from "@shared/enum/bucket-status";

const fCollection = environment.payloadEcommerce;

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  ref!: DocumentReference | any;
  data!: DocumentData | any;
  private _firestore: Firestore = inject(Firestore);

  get finalUrl() {
    return environment.firebaseVerify;
  }

  constructor(
    private auth: Auth,
    private http: HttpClient,
    private _sessionService: SessionService
  ) {
    auth.tenantId = environment.tenantId;
  }

  async initApp() {
    await this.loginFirebase();
    await this.getFirebaseClientId();
    this._setCustomClaims()
  }

  async loginFirebase() {
    const user = await this.isLoggedIn();
    if (!user) {
      await this.signInAnonymously();
    }
  }

  async signInAnonymously(): Promise<User> {
    return await signInAnonymously(this.auth)
      .then(({user}) => {
        this._saveUserInStorage(user)
        return user
      });
  }

  async signIn(email: string, password: string): Promise<User> {
    return await signInWithEmailAndPassword(this.auth, email, password)
      .then(({user}) => {
        this._saveUserInStorage(user)
        return user
      })
  }

  isLoggedIn(): Promise<User | null> {
    return new Promise((resolve) => {
      onAuthStateChanged(this.auth, (user) => {
        if (user) {
          resolve(user)
        } else {
          resolve(null)
        }
      }, () => resolve(null));
    });
  }

  getToken(): Promise<string> {
    return new Promise((resolve) => {
      onAuthStateChanged(this.auth, (user) => {
        if (user) {
          user.getIdToken()
            .then((token) => resolve(token))
            .catch(() => resolve(''));
        } else {
          this.auth.currentUser?.getIdToken(true)
            .then(token => resolve(token))
            .catch(() => resolve(''))
        }
      })
    })
  }

  async signUp(email: string, password: string): Promise<UserCredential> {
    return await createUserWithEmailAndPassword(this.auth, email, password);
  }

  async getFirebaseClientId() {
    const docRef = doc(getFirestore(), 'config', 'client-id');
    const docSnap = getDoc(docRef);
    const document = await docSnap;
    const client = document.data() ?? {};
    localStorage.setItem('client_id', client['client-id']);
  }

  verifyEmail(form: any) {
    return this.http.post(this.finalUrl, form);
  }

  async doLogout() {
    return await signOut(this.auth);

  }

  isAuthenticatedUser(): Observable<boolean> {
    return new Observable(observer => {
      onAuthStateChanged(this.auth, (user) => {
        if (!user || user.isAnonymous || !user.emailVerified) {
          observer.next(false)
        } else {
          observer.next(true)
        }
      })
    })
  }

  private _saveUserInStorage(user: User) {
    const token = user && (user as any)['accessToken']
    localStorage.setItem('token', token)
  }

  async statusCarga(documentId: any) {
    const docRef = doc(getFirestore(), `/${fCollection}/${documentId.id}`);
    const docSnap = getDoc(docRef);
    const document = await docSnap;
    const product = document.data() ?? {};
    return product
  }

  statusCargasTotalParcial(documentId: any): Observable<any> {
    const db = getFirestore();
    this.ref = doc(db, `/${fCollection}/${documentId.id}`);
    const docSnap = getDoc(this.ref);
    return docData(this.ref)
      .pipe(
        tap((data) => {
          //console.log(data)
        })
      );
  }

    statusCargasTotal(documentId: any): Observable<any> {
      const id = documentId?.data?.id ? documentId?.data?.id :  documentId.id;

      const docRef = doc(this._firestore, `/${fCollection}/${id}`);
      return docData(docRef).pipe(
        catchError(error => {
          console.error("Error al obtener el documento:", error);
          return throwError(() => new Error("Documento no encontrado"));
        })
      );
    }

  async statusUpload(documentId: any) {
    return new Promise<BucketStatus>(res => {
      const docRef = doc(getFirestore(), `/${fCollection}/${documentId}`);
      onSnapshot(docRef, (data) => {
        if(data.data()) {
          const status = (data.data() as DocumentData)['status']
          if (status === BucketStatus.FINISHED || status === BucketStatus.FAILED) {
            res(status)
          }
        }
      }, error => {
        res(BucketStatus.FAILED)
      })
    })
  }

  private _setCustomClaims() {
    const token = localStorage.getItem('token')
    const decodedToken = jwt_decode(token as any)
    const {shopId, role, email} = decodedToken as any
    const setInSession = {email: email ?? '', shopId: shopId ?? '', role: role ?? ''}
    this._sessionService.setSessionStatus(setInSession)
  }

   setCustomClaimsRole() {
    const token = localStorage.getItem('token')
    const decodedToken = jwt_decode(token as any)
    const {shopId, role, email} = decodedToken as any
    return role;
    //const setInSession = {email: email ?? '', shopId: shopId ?? '', role: role ?? ''}
    //this._sessionService.setSessionStatus(setInSession)
  }

}
