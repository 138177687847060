<div *ngIf="modal$ | async as modals" class="z-[999]">
  <div *ngIf="modals.show" id="toast-undo"
          style="background-color: rgba(74, 167, 133, 1);"
          class="z-[999] absolute left-279 bottom-0 md:left-[279px] md:bottom-0 flex items-center
                 w-[414px] md:w-[446px] p-4 rounded-lg shadow "
          role="alert">
    <div class="text-sm font-norma flex justify-center items-start">
      <fa-icon class="text-white text-lg " [icon]="faCircle"></fa-icon>
      <p class="text-white ml-4 text-lg w-[280px] leading-tight">{{modals.title}}</p>
    </div>
    </div>
</div>
