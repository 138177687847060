import { Pipe, PipeTransform } from '@angular/core';
import {StatusType} from "@shared/types/status.type";

@Pipe({
  name: 'statusValue'
})
export class StatusValuePipe implements PipeTransform {

  transform(value: StatusType, ...args: unknown[]): string {
    return this._capitalize(value)
  }

  private _capitalize(value: StatusType): string {
    return (value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()).split('_').join(' ');
  }

}
