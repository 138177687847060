import { Injectable } from '@angular/core';
import { ModalInterface } from '@shared/interfaces/modal.interface';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private modal = new BehaviorSubject<ModalInterface>({show: false})

    modal$ = this.modal.asObservable();

    constructor() {
    }

    show(title1: string, description1: string, type: 'SUCCESS' | 'WARNING' | 'ERROR' = 'SUCCESS', duration: number = 3) {

        let title, description = '';


        this.modal.next({show: true , title:title1, description, type})
        this._close(duration * 1000)
    }

    private _close(duration: number) {
        setTimeout(() => {
            this.modal.next({show: false})
        }, duration)
    }

}
