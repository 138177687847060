import { Component, DestroyRef } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import { ROUTES_APP } from 'src/app/app.routes';

@Component({
  selector: 'sho-redirection-message-login',
  templateUrl: './redirection-message-login.component.html',
  styleUrls: ['./redirection-message-login.component.css']
})
export class RedirectionMessageLoginComponent {
  mediaObserverSubscription:any

  constructor(
    private mediaObserver: MediaObserver,
    private router: Router,
    private _destroyRef: DestroyRef
  ){

  }
  ngOnInit() {
    this.mediaObserverSubscription = this.mediaObserver.asObservable().pipe(
      debounceTime(2000),
      takeUntilDestroyed(this._destroyRef), // Retrasa las llamadas durante 2 segundos (2000 ms)
    ).subscribe((changes) => {
      changes.forEach((change) => {
        //console.log(change.mqAlias)
        if (change.mqAlias !== 'xs') {
          this.router.navigateByUrl(`/${ROUTES_APP.SIGNIN}`);
        } else {
          // Estás en una pantalla grande

          // Realiza acciones específicas para pantallas grandes
        }
      });
    });
  }

}
