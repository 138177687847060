<div class="mt-[74px] w-full h-screen">

  <img src="./../../../../assets/images/Logo soho Seller.png" class="ml-[33px]" style="Width:142.3px;Height:60.55px" alt="soho Logo"/>
  <div class="w-full ml-[33px]">
    <p class="w-[326px] h-[45px] font-bold mt-[197px] text-xl text-[#122B36]">Queremos que tengas la mejor experiencia</p>
  </div>

  <div class="w-full ml-[33px]">
    <p class="font-medium w-[322px] h-[89px] mt-[24px] text-[#122B36] text-base">Es por esto que te recomendamos continuar el uso de nuestra plataforma mediante un equipo de escritorio,
      de esta forma puedes disfrutar de todas las funcionalidades de la mejor manera
    </p>
  </div>

  <p class="ml-[33px] font-semibold w-[213px] h-[36px] mt-[120px] text-[#146D81] text-base">Saludos de Soho Seller Center</p>

</div>
