import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'c-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadComponent {
  @ViewChild('advancedFileInput') advancedFileInput!: ElementRef;
  uploadedFiles: File[] = [];
  @Input() multiple = false;
  @Output() onUploadFiles: EventEmitter<File[]> = new EventEmitter();
  @Output() onRemoveFiles: EventEmitter<File[]> = new EventEmitter();

  isFileSelected(file: File): boolean {
    for (const sFile of this.uploadedFiles) {
      if ((sFile.name + sFile.type + sFile.size) === (file.name + file.type + file.size)) {
        return true;
      }
    }
    return false;
  }

  onFileSelect(event: any): void {
    const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    for (const file of files) {
      if (file.type !== "application/vnd.ms-excel" && file.type !== "text/csv" && file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") return;
      if (!this.isFileSelected(file)) {
        this.uploadedFiles.push(file);
      }
    }

    if (this.advancedFileInput && this.advancedFileInput.nativeElement) {
      this.advancedFileInput.nativeElement.value = '';
    }
    this.onUploadFiles.emit(this.uploadedFiles);
  }

  removeFile(i: number): void {
    this.uploadedFiles.splice(i, 1);
    this.onRemoveFiles.emit(this.uploadedFiles);
  }

  removeAllFiles(): void {
    this.uploadedFiles = [];
    this.onRemoveFiles.emit(this.uploadedFiles);
  }

}
