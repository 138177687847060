import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NavLayoutComponent} from '@shared/components/layouts/nav-layout/nav-layout/nav-layout.component';
import {UserIsLoggedIn, UserIsNotLoggedIn} from '@shared/guards/auth.guard';
import {OnBoardingComponent} from '@views/nav/on-boarding/on-boarding.component';
import {RedirectionMessageLoginComponent} from '@views/nav/redirection/redirection-message-login/redirection-message-login.component';
import {RedirectionMessageComponent} from '@views/nav/redirection/redirection-message/redirection-message.component';
import {NotFoundComponent} from '@views/not-found/not-found.component';
import { SessionCreatePasswordComponent } from '@views/sessions/session-create-password/session-create-password.component';


const routes: Routes = [
  {
    path: '',
    canActivate: [UserIsNotLoggedIn],  //luego cambiarlo por el redirect platzi.
    loadChildren: () => import('./views/sessions/sessions.module').then((m) => m.SessionsModule),
  },
  {
    path: 'onboarding',
    component: OnBoardingComponent,
    canActivate: [UserIsLoggedIn],
    title: 'Onboarding'
  },
  {
    path: 'redirection',
    component: RedirectionMessageComponent,
    title: 'Página de redireccion'
  },
  {
    path: 'redirection-login',
    component: RedirectionMessageLoginComponent,
    title: 'Página de redireccion'
  },
  {
      path: 'change-password',
      component: SessionCreatePasswordComponent,
      title: 'Cambiar Contraseña'
  },
  {
    path: 'shop/:shopId',
    canActivate: [UserIsLoggedIn],
    component: NavLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./views/nav/nav.module').then((m) => m.NavModule),

      }
    ],
  },
  {
    path: '',
    canActivate: [UserIsLoggedIn],
    component: NavLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./views/nav/nav.module').then((m) => m.NavModule),

      }
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
    title: 'Página no encontrada'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    bindToComponentInputs: true,
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
