import {Directive, ElementRef, inject, Input, OnInit} from '@angular/core';
import {RoleService} from "@shared/services/role.service";
import {Role} from "@shared/types/role.type";

@Directive({
  selector: '[shoRole]',
  standalone: true
})
export class RoleDirective implements OnInit {
  private readonly _role = inject(RoleService);

  @Input() shoRole!: Role[];

  constructor(private readonly el: ElementRef) { }

  ngOnInit(): void {
    if(this.shoRole.includes('*')) return

    this._role.hasRole(this.shoRole).subscribe((hasRole) => {
      if(!hasRole) {
        this.el.nativeElement.style.display = 'none';
        this.el.nativeElement.remove();
      }
    });
  }

}
