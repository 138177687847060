export const environment = {
  production: false,
  apiUrl: "https://dev-backoffice.soho.market",
  firebaseConfig: {
    apiKey: "AIzaSyBeJBAckugwdEH8HzWrP7qv957f0bAscVg",
    authDomain: "sza-preprod.firebaseapp.com",
    projectId: "sza-preprod",
    storageBucket: "dev.storage.soho.market",
    messagingSenderId: "359616739836",
    appId: "1:359616739836:web:b82efbfe9542cd56221a79",
    measurementId: "G-JHNJD696KH"
  },
  tenantId: "soho-backoffice-dev-gj2t7",
  baseURL: "https://dev.backoffice.api.soho.market/api/v1/",
  endpoints: {
    shop: 'shop',
    stores: 'tiendas',
    order: 'order',
    orders: 'orders',
    comment: 'comment',
    returns: 'returns',
    n_packages: 'n_packages',
    products: 'products',
    receipt: 'receipt',
    summary: 'summary',
    sendEnviameByorderId: 'sendEnviameByorderId',
    recoverPassword: 'recover-password',
    session: 'session',
    register: 'register',
    cities: "cities",
    propertyTypes: "property-types",
    properties: "properties",
    posts: "posts",
    emailServices: "email/services",
    emailContact: "email/contact",
    postAsFavorite: "mark-favorite",
    realState: "real-state",
    userData: "user-data",
    userPosts: "posts-user",
    uploadProducts: "upload/products",
    upload: "upload",
    productsEcommerce: "sync/list/products",
    uploadProductsEcommerce: "sync/pull",
    productsPending: "shadow/product",
    categoriesFilterPending: "shadow/category",
    categoriesShop: "category",
    editProductPrincipal: "shadow/product",
    tag: "tag",
    deleteProducts: "shadow/product",
    categoriesMarket: "marketplace/category",
    warehouseId: "warehouse",
    individualproduct: "individual-product",
    individualproducts: "individualProducts",
    product: "product",
    individualproductD: "individualProduct",
    typeAtributte: "typeAtributte",
    atributte: "atributte",
    individualProductVariant: "individualProductVariant",
    variantsIsMean: "variantsIsMean",
    warehouses: "warehouses",
    categoriesMarketPlace: "categoriesMarketPlace",
    variant: "variant",
    principalProduct: "principalProduct",
    executive: "executive",
    main_variants: "main-variants",
    tags: "individual-product/tags",
    users: 'usuarios'
  },
  baseUrlMultivende: "https://dev.multivende.api.soho.market/api/v1/",
  endpointsMultivende: {
    status: "sync/status",
    clientId: "sync/client-id",
    accessToken: "sync/access-token",
    categories: "sync/categories",
    listCategories: "/sync/list/categories",
    listProducts: "/sync/list/products",
    listPrices: "/sync/price-lists",
    listWarehouses: "/sync/warehouses",
    listCategoriesMarket: "marketplace-category",
    productAttributes: "/sync/list/product-attributes",
    deleteCategory: "/sync/categories",
    deleteAttrib: "/sync/attributes",
    postAttrib: "/sync/attributes",
    fullCharge: "/sync/pull"

  },
  multivendeAuth: " https://app.multivende.com/apps/authorize",
  multivendeClientId: "623967816173",
  firebaseVerify: "https://www.googleapis.com/identitytoolkit/v3/relyingparty/setAccountInfo?key=AIzaSyDGEN8PeSAfqRlwA-Z9wUwnalSm7To0_l4",
  googleMapApiKey: "AIzaSyCJx9U6UPxx8rNXXhVCdS3WK9RBvFf4HGc",
  recaptcha: {
    publicKey: "0x4AAAAAAAJzdEg4xQ9YQASn"
  },
  emailNotFound: {
    emailMessage: "There is no user record corresponding to the provided email."
  },
  payloadEcommerce: 'soho-development-uploads-metadata',
};
