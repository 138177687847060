import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {Auth, User} from "@angular/fire/auth";
import {filter, ReplaySubject, take, tap} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly _auth = inject(Auth);
  private _http = inject(HttpClient)

  private readonly _user = new ReplaySubject<User | null>(1);

  get urlRecoverPassword() {
    return `${environment.baseURL}auth/${environment.endpoints.recoverPassword}`
  }

  get user() {
    return this._user.pipe(
      filter((user): user is User => !!user),
      tap((elementoUsuario) => console.log({elementoUsuario})),
      take(1)
    );
  }

  forgotPassword(email: string, captcha: string) {
    const headers = {
      'x-captcha-token': captcha
    }
    return this._http.post<any>(this.urlRecoverPassword, {}, {headers, params: {email}}
    )
  }
}

