import { Component } from '@angular/core';
import { faEye, faEyeSlash, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { timer } from 'rxjs';

@Component({
  selector: 'sho-redirection-message',
  templateUrl: './redirection-message.component.html',
  styleUrls: ['./redirection-message.component.css']
})
export class RedirectionMessageComponent {
  mostrarDiv = true;
  faCircleCheck = faCircleCheck;

  ngOnInit(): void {
        // Oculta el mensaje de éxito después de 3 segundos
        timer(3000).subscribe(() => {
          this.mostrarDiv = false;
        });
    }

}
