import { Component, Input } from '@angular/core';

@Component({
  selector: 'sho-table-csv',
  templateUrl: './table-csv.component.html',
  styleUrls: ['./table-csv.component.css']
})
export class TableCsvComponent {
  @Input() columns: string[] = [];
  @Input() rows: string[] = [];

}
