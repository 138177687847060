export const RoleName = {
  SOHO_EXECUTIVE: 'SOHO_EXECUTIVE',
  SHOP_EXECUTIVE: 'SHOP_EXECUTIVE',
  SHOP_ADMIN: 'SHOP_ADMIN',
  SOHO_ADMIN: 'SOHO_ADMIN',
  ALL: '*'
} as const;

export const NameOfRole = {
  [RoleName.SOHO_EXECUTIVE]: 'Ejecutivo Soho',
  [RoleName.SHOP_EXECUTIVE]: 'Ejecutivo Tienda',
  [RoleName.SHOP_ADMIN]: 'Súper Admin Tienda',
  [RoleName.SOHO_ADMIN]: 'Súper admin Soho',
  ['*']: ''
}

export type RoleSho = 'SOHO_EXECUTIVE' | 'SHOP_ADMIN' | 'SHOP_EXECUTIVE' | 'SOHO_ADMIN'
