<form novalidate class="space-y-4" (ngSubmit)="confirmDelete()">
  <div
    class="pt-[40px] pb-[24px] px-[20px] bg-gray-100 relative rounded-xl shadow"
  >
    <div class="flex space-x-2 mb-5 flex-col justify-center items-center">
      <svg
        style="width: 27.63px; height: 27.63px; top: 3.19px; left: 3.19px"
        xmlns="http://www.w3.org/2000/svg"
        fill="rgba(245, 71, 72, 1)"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="text-white"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
        />
      </svg>
      <h3 class="text-center text-lg font-bold text-[#122B36]">
        ¿Confirmas que deseas eliminar estos 8 productos?
      </h3>
      <h4 class=" mt-2 text-center text-base font-medium text-[#122B36] leading-4 h-[44px]">
        Si confirmas esta acción se eliminarán estos productos de tu tienda y no
        podrás recuperarlos
      </h4>
    </div>
    <div class="flex flex-col w-full justify-center items-center"></div>
    <div class="flex w-full mt-[30px] justify-center">
      <div class="flex justify-between w-[389px]">
        <app-btn-perfil (click)="close()" class="w-[185px]" color="secondary"
          >Cancelar</app-btn-perfil
        >
        <app-btn-perfil
          class="w-[185px]"
          typeBtn="submit"
          [loading]="status === 'loading'"
          color="confirm"
          >Eliminar productos</app-btn-perfil
        >
      </div>
    </div>
  </div>
</form>
