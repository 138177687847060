import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {

  /**
   * Extract a length from text and concat ...
   * @param value string
   * @return An string
   */
  transform(value: number): string {
    const formatter = Intl.NumberFormat("en", { notation: "compact" });
    return formatter.format(value);
  }

}
