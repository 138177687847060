import { Component, Input  } from '@angular/core';

@Component({
  selector: 'sho-card-csv',
  templateUrl: './card-csv.component.html',
  styleUrls: ['./card-csv.component.css']
})
export class CardCsvComponent {
  @Input() width: string = '325px';
  @Input() height: string = '74px';
  @Input() backgroundColor: string = '#F5474826';
  @Input() iconWidth: string = '20%';
  @Input() iconSrc?: string;
  @Input() iconAlt: string = '';
  @Input() textWidth: string = '80%';
  @Input() textColor: string = '#122B36';
  @Input() cardText: string = '';

}
