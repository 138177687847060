import { Component, Input } from '@angular/core';

@Component({
  selector: 'sho-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.css']
})
export class LoadingSpinnerComponent {
  @Input() cargando: boolean = false;
  @Input() progress: number = 100;

}
