import { DialogRef } from '@angular/cdk/dialog';
import { Component, Output, EventEmitter  } from '@angular/core';
import { IconDefinition, faCircleCheck, faCircleXmark, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { RequestStatus } from '@shared/interfaces/request-status.model';
import { ModalIcon, ModalSettings } from '../modal-generic/modal-model';

@Component({
  selector: 'sho-modal-confirm-delete-video-variant',
  templateUrl: './modal-confirm-delete-video-variant.component.html',
  styleUrls: ['./modal-confirm-delete-video-variant.component.scss']
})
export class ModalConfirmDeleteVideoVariantComponent {


  @Output() confirmDeleteVideo = new EventEmitter<string>();
  status: RequestStatus = 'init';
  faCircleIcon:IconDefinition;
  settings:ModalSettings={};

  constructor( private dialogRef: DialogRef ) {

    this.settings = dialogRef.config.data;

    this.settings.paragraphSize =  this.settings.paragraphSize ? this.settings.paragraphSize :"w-[350px]";

    this.faCircleIcon = this.settings.icon == ModalIcon.Success ? faCircleCheck : faCircleExclamation;
    if(ModalIcon.Success){
      this.faCircleIcon = faCircleCheck;
    }
    if(ModalIcon.Error){
      this.faCircleIcon = faCircleXmark;
    }
    if(ModalIcon.Warning){
      this.faCircleIcon = faCircleExclamation;
    }

  }

  isSuccessColor ():boolean{
    return this.settings.icon == ModalIcon.Success;
  }

  close() {
    this.dialogRef.close();
  }
  confirmDelete() {
    this.status = 'loading';
    this.confirmDeleteVideo.emit(this.settings.video);
    // Cierra el modal después de emitir el evento
    this.dialogRef.close();
  }

}
