<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 rounded-lg">
  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? toggleAllRows() : null"
                    [color]="'primary'"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    [color]="'primary'"
                    (change)="$event ? toggleSubproducts(row) : null"
                    [checked]="allSelectedTree(row)"
                    [indeterminate]="indeterminateTree(row)"
                    [aria-label]="checkboxLabel(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <!-- Position Column -->
  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef class="mat-header-cell-centrado titulos font-semibold text-base leading-[22px] text-[#122B36]"><span class="titulos font-semibold text-base leading-[22px] text-[#122B36]">No</span></th>
    <td mat-cell *matCellDef="let data" class="text-center">
    <div *ngIf="data.position" class="titulos font-normal text-base leading-[19.2px] text-[#122B36]">{{data.position}}</div>
    <div *ngIf="!data.position">-</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>
      <span [style.paddingLeft.px]="52" class="titulos font-semibold text-base leading-[22px] text-[#122B36]">Nombre</span>
    </th>
    <td mat-cell *matCellDef="let data">
      <div class="flex items-center">
        <button mat-icon-button
              [style.visibility]="!data.expandable ? 'hidden' : ''"
              [style.marginLeft.px]="data.level * 32"
              (click)="treeControl.toggle(data)">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(data) ? 'chevron_right' : 'expand_more'}}
        </mat-icon>
      </button>
      <p class="titulos font-normal text-base leading-[19.2px] text-[#122B36]">
        {{data.title}}
      </p>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="sku">
    <th mat-header-cell *matHeaderCellDef class="mat-header-cell-centrado titulos font-semibold text-base leading-[22px] text-[#122B36]"><span class="titulos font-semibold text-base leading-[22px] text-[#122B36]">SKU</span></th>
    <td mat-cell *matCellDef="let data">
      <div *ngIf="data.sku" class="titulos font-normal text-base leading-[19.2px] text-[#122B36]">{{data.sku}}</div>
      <div *ngIf="!data.sku">-</div>
    </td>
  </ng-container>
  <ng-container matColumnDef="precio">
    <th mat-header-cell *matHeaderCellDef class="mat-header-cell-centrado"><span class="titulos font-semibold text-base leading-[22px] text-[#122B36]">Precio</span></th>
    <td mat-cell *matCellDef="let data">
      <div *ngIf="data.price" class="titulos font-normal text-base leading-[19.2px] text-[#122B36]">{{data.price | currency:'USD':'symbol':'1.0-0'}}</div>
      <div *ngIf="!data.price">-</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="Atributos">
    <th mat-header-cell *matHeaderCellDef class="mat-header-cell-centrado"><span class="titulos font-semibold text-base leading-[22px] text-[#122B36]">Atributos</span></th>
    <td mat-cell *matCellDef="let data" class="titulos font-normal text-base leading-[19.2px] text-[#122B36]">
      <button mat-raised-button (click)="openDialog(data.selectedOptions)" [style.visibility]="data.expandable ? 'hidden' : ''">
        <img src="../../../../assets/svg/Icon-menu.svg" alt="">
      </button>
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
