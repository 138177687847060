import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusTransform'
})
export class StatusTransformPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return this._transform(value);
  }

  private _transform(value: string): string {
    return mapStatus.get(value) ?? value;
  }
}

export const mapStatus: Map<string, string> = new Map<string, string>([
  ['Envio aprobado','Envío aprobado'],
  ['Envio rechazado','Envío rechazado'],
])
