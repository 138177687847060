<button
  title="Carga"
  [type]="typeBtn"
  [disabled]="disabled || loading"
  [ngClass]="colors"
  [ngStyle]="styles"
  class="w-full font-bold text-base px-5 py-2 rounded-[7px] leading-snug "


>
  <fa-icon
    *ngIf="loading"
    class="mr-2"
    [icon]="faSpinner"
    [pulse]="true"
  ></fa-icon>
  <ng-content></ng-content>
</button>
