<div class="bg-white rounded-xl h-[290px]">
    <div class="flex flex-col justify-center items-center h-full ">
        <fa-icon class="text-2xl" [icon]="faCircleIcon"
                 [ngClass]="{'text-[#0CA348;]':isSuccessColor(), 'text-[#F54748;]':!isSuccessColor()}"></fa-icon>
        <h1 class="text-[#122B36;] text-center text-lg font-bold w-[400px] leading-5 mt-[18px]">
            {{settings.tittle}}
        </h1>
        <p class="text-[#122B36;] text-center mt-[18px] mb-[30px] text-base font-medium leading-5"
            [ngClass]="settings.paragraphSize">
            {{settings.paragraph}}
        </p>
        <app-btn class=" w-[153px]" 
                [styles]="{height:'50px', background: '#146D81'}" (click)="closed()" 
                color="success">        
            Aceptar
        </app-btn>
    </div>   
</div>