import { Component, Input, OnInit } from '@angular/core';
import { initFlowbite } from 'flowbite';

@Component({
  selector: 'sho-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css']
})
export class AccordionComponent implements OnInit {
  @Input() accordionItems: any[] = [];
  expandedItemIndex: number | null = null;

  toggleAccordion(index: number): void {
    if (this.expandedItemIndex === index) {
      this.expandedItemIndex = null;
    } else {
      this.expandedItemIndex = index;
    }
  }

  ngOnInit(): void {
    //initFlowbite();
  }

}
