import {Component, DestroyRef, HostListener, inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RequestStatus} from '@shared/interfaces/request-status.model';
import {faCartShopping} from '@fortawesome/free-solid-svg-icons';
import {finalize, interval, take} from 'rxjs';
import {SessionService} from "@shared/services/session.service";
import {Dialog} from "@angular/cdk/dialog";
import {ModalShopSelectorComponent} from "@shared/components/modal-shop-selector/modal-shop-selector.component";
import {ShopService} from "@shared/services/shop.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ROUTES_APP} from "../../../app.routes";
import { environment } from '@environments/environment';
import { NameOfRole, RoleName, RoleSho } from '@shared/const/role.sho';
import { FirebaseService } from '@shared/services/firebase.service';


@Component({
  selector: 'app-on-boarding',
  templateUrl: './on-boarding.component.html',
  styleUrls: ['./on-boarding.component.css']
})
export class OnBoardingComponent implements OnInit {

  currentSlide:number = 1;
  status: RequestStatus = 'init';
  faCartShopping = faCartShopping

  private _firebase = inject(FirebaseService)
  private router = inject(Router)

  protected isLoading: boolean = false;

  constructor(
    private _dialog: Dialog,
    private _router: Router,
    private _sessionService: SessionService,
    private _shopService: ShopService,
    private readonly _destroyRef: DestroyRef
  ) {
    interval(5000).subscribe(() => {
      this.incrementarContador();
    });
  }

  ngOnInit(): void {
    const onBoardingWasVisited = localStorage.getItem('onBoardingWasVisited')
    if (onBoardingWasVisited) {
      this.goToDashboard()
    }
  }

  get getRoleName() {
    return this._getNameByRole(this._sessionService.getRoleStore())
  }

  private _getNameByRole(role: string): string {
    return NameOfRole[role as RoleSho]
  }

  private incrementarContador() {
    if (this.currentSlide < 3) {
      this.currentSlide++;
    }
  }

  private _setCookieOnBoarding() {
    localStorage.setItem('onBoardingWasVisited', 'true')
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.currentSlide == 1) {
      if (event.key === 'ArrowLeft') {
        this.currentSlide = 1;
      }
      if (event.key === 'ArrowRight') {
        this.currentSlide = 2;
      }
    } else {
      if (this.currentSlide == 2) {

        if (event.key === 'ArrowLeft') {
          this.currentSlide = 1;
        }
        if (event.key === 'ArrowRight') {
          this.currentSlide = 3;
        }
      } else {
        if (this.currentSlide == 3) {

          if (event.key === 'ArrowLeft') {
            this.currentSlide = 2;
          }
          if (event.key === 'ArrowRight') {
            this.goToDashboard();
          }
        }
      }
    }
  }

  nextSlide() {
    this.goToDashboard()
  }

  skipOnboarding() {
    this.goToDashboard()
  }

  continue(number: any) {
    this.currentSlide = number;
  }

  goToDashboard(): void {
    this._setCookieOnBoarding()
    this.isLoading = true
    this._shopService.getShops(true)
      .pipe(
        finalize(() => this.isLoading = false),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe({
        next: (res) => {
          //console.log(res);
          localStorage.setItem("shopLists", JSON.stringify(res));
          if (res.length === 0) return;

        const shopId = res[0].id; // Obtener el ID de la tienda
        const isSuperAdminSoho = this.getRoleName === NameOfRole[RoleName.SOHO_ADMIN];

        // ruta de navegación basada en el rol
        const route = isSuperAdminSoho ? ROUTES_APP.PROFILE : ROUTES_APP.PRODUCT;

        // ID de la tienda en la sesión
        this._sessionService.setSessionShopId(shopId);

        // URL construida
        this._router.navigateByUrl(`${environment.endpoints.shop}/${shopId}/${route}`).then();

        /* this._dialog.open(ModalShopSelectorComponent, {
            maxWidth: '900px',
            disableClose: true
          }).closed
            .pipe(take(1))
            .subscribe((res) => {
              this._sessionService.setSessionShopId(res as string)
              this._router.navigateByUrl(`shop/${res}/${ROUTES_APP.PRODUCT}`).then()
            }) */
        },
        error: (err) => {
          console.log(err)
        }
        });
  }
}
