import {NgModule} from '@angular/core';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {RouterModule, RouterOutlet} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LogoNavbarSohoComponent} from './components/logo-navbar-soho/logo-navbar-soho.component';
import {NavLayoutComponent} from './components/layouts/nav-layout/nav-layout/nav-layout.component';
import {HeaderComponent} from './components/header/header/header.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ButtonComponent} from './components/buttonPerfil/button.component';
import {SelectDropdownComponent} from './components/select-dropdown/select-dropdown.component';
import {ItemGroupComponent} from './components/select-dropdown/item-group/item-group.component';
import {ModalConfirmComponent} from './components/modal-confirm-product-delete/modal-confirm/modal-confirm.component';
import {
  ModalConfirmDeleteComponent
} from './components/modal-confirm-delete-selected-product/modal-confirm-delete/modal-confirm-delete.component';
import {
  ModalConfirmStarComponent
} from './components/modal-confirm-product-star/modal-confirm-star/modal-confirm-star.component';
import {StatusValuePipe} from './pipes/status-value.pipe';
import {UploadComponent} from './components/c-upload/upload.component';
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import {CardCsvComponent} from './components/card-csv/card-csv.component';
import {TableCsvComponent} from './components/table-csv/table-csv.component';
import {AccordionComponent} from './components/accordion/accordion.component';
import {WrapTextPipe} from "@shared/pipes/wrap.text.pipe";
import {ShortNumberPipe} from "@shared/pipes/short.number.pipe";
import {LoadingSpinnerComponent} from "@shared/components/loading-spinner/loading-spinner.component";
import {TableTreeEcommerceComponent} from "@shared/components/table-tree-ecommerce/table-tree-ecommerce.component";
import {SpinnerEcommerceComponent} from "@shared/components/spinner-ecommerce/spinner-ecommerce.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatTableModule} from "@angular/material/table";
import {MatButtonModule} from "@angular/material/button";
import {
  ModalAttributesEcommerceComponent
} from "@shared/components/modal-attributes-ecommerce/modal-attributes-ecommerce.component";
import {TableTreePendingComponent} from "@shared/components/table-tree-pending/table-tree-pending.component";
import {
  ModalConfirmDeletePendingComponent
} from "@shared/components/modal-confirm-delete-pending/modal-confirm-delete-pending.component";
import {MatMenuModule} from "@angular/material/menu";
import {TableMenuComponent} from "@shared/components/table-menu/table-menu.component";
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { ModalConfirmDeleteImageVariantComponent } from './components/modal-confirm-delete-image-variant/modal-confirm-delete-image-variant.component';
import { ModalConfirmDeleteVideoVariantComponent } from './components/modal-confirm-delete-video-variant/modal-confirm-delete-video-variant.component';
import { TableTreeProductsComponent } from './components/table-tree-products/table-tree-products.component';
import {RoleDirective} from "@shared/directives/role.directive";
import { StatusTransformPipe } from './pipes/status-transform.pipe';


const components = [
  LogoNavbarSohoComponent,
  NavLayoutComponent,
  ButtonComponent,
  SelectDropdownComponent,
  ItemGroupComponent,
  HeaderComponent,
  ModalConfirmComponent,
  ModalConfirmDeleteComponent,
  ModalConfirmStarComponent,
  CardCsvComponent,
  WrapTextPipe,
  ShortNumberPipe,
  UploadComponent,
  TableCsvComponent,
  AccordionComponent,
  LoadingSpinnerComponent,
  StatusValuePipe,
  TableTreeEcommerceComponent,
  TableTreePendingComponent,
  TableTreeProductsComponent,
  SpinnerEcommerceComponent,
  SpinnerEcommerceComponent,
  ModalAttributesEcommerceComponent,
  NavLayoutComponent,
  HeaderComponent,
  ModalConfirmComponent,
  ModalConfirmDeleteComponent,
  ModalConfirmStarComponent,
  ShortNumberPipe,
  WrapTextPipe,
  ModalConfirmDeletePendingComponent
]


@NgModule({
  declarations: [...components, CustomCurrencyPipe, ModalConfirmDeleteImageVariantComponent, ModalConfirmDeleteVideoVariantComponent, StatusTransformPipe],
    exports: [...components, CustomCurrencyPipe, CustomCurrencyPipe, StatusTransformPipe],
  imports: [
    CommonModule,
    RouterOutlet,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    MatChipsModule,
    MatIconModule,
    ClipboardModule,
    MatCheckboxModule,
    MatTableModule,
    MatButtonModule,
    MatMenuModule,
    TableMenuComponent,
    RoleDirective,
  ],
  providers: [
    CurrencyPipe,
    CustomCurrencyPipe,
    WrapTextPipe,
    ShortNumberPipe,
    StatusValuePipe
  ]
})
export class SharedModule {
}
