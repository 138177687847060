import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {MenuItem} from "@shared/interfaces/table-menu.model";
import {ShoColumnTypeActions} from "@shared/enum/table.enum";

@Component({
  selector: 'sho-table-menu',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatMenuModule, MatButtonModule],
  templateUrl: './table-menu.component.html',
  styleUrls: ['./table-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableMenuComponent {
  @Input({required: true}) items!: MenuItem[]
  @Output() onSelected: EventEmitter<ShoColumnTypeActions> = new EventEmitter<ShoColumnTypeActions>()

  selected(actionId: ShoColumnTypeActions) {
    this.onSelected.emit(actionId)
  }
}
