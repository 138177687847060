<div class="p-6 relative overflow-x-auto rounded-lg">
  <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 rounded-lg">
    <thead class="bg-white border-b rounded-lg">
      <tr>
        <ng-container *ngFor="let column of columns">
          <th scope="col" class="px-6 py-3 font-medium text-base leading-[19.2px] text-[#122B36]">
            {{ column }}
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let row of rows">
        <tr class="bg-white border-b">
          <ng-container *ngFor="let cell of row">
            <td class="px-6 py-4 font-medium text-base leading-[19.2px] text-[#122B36]">
              {{ cell }}
            </td>
          </ng-container>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

