export enum ROUTES_APP {
  EDIT_VARIANT_INDIVIDUAL = 'EditVariantindividual',
  EDIT_INDIVIDUAL_P = 'EditIndividualP',
  CREAR_VARIANTE = 'crear-variante',
  INDIVIDUAL = 'individual',
  INDIVIDUALP = 'individualP',
  REDIRECTION = 'redirection',
  ONBOARDING = 'onboarding',
  DASHBOARD = 'dashboard',
  ORDERS = 'orders',
  RETURNS = 'returns',
  LOGISTICS='logistics',
  SHIPMENTS = 'shipments',
  WITHDRAWAL = 'withdrawal',
  MANIFESTS = 'manifests',
  LIQUIDATIONS = 'liquidations',
  SHOPS = 'shops',
  PRODUCT = 'product',
  PENDING = 'pending',
  PROFILE = 'profile',
  OFERTAS = 'offers',
  MENSAJES = 'messages',
  ESTADISTICAS = 'statistics',
  SIGNIN = 'signin',
  MULTIVENDE = 'multivende',
}
