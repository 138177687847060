import { Pipe, PipeTransform } from '@angular/core';
import {CurrencyPipe} from "@angular/common";

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {

  transform(value: any, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): any {
    const currencyPipe: CurrencyPipe = new CurrencyPipe('en_US');
    const formattedValue = currencyPipe.transform(value, currencyCode, display, digitsInfo, locale) ?? '0'
    const [integerPart, decimalPart] = formattedValue.split(currencyPipe.transform(0, currencyCode, display, digitsInfo, locale) ?? '0');

    return  integerPart.replace(/,/g, '.');
  }

}
