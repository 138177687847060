<!-- accordion -->
<div id="accordion-collapse" data-accordion="collapse" class="p-6">
  <ng-container *ngFor="let item of accordionItems; let i = index">
    <h2 id="accordion-collapse-heading-{{i + 1}}">
      <button type="button"
        class="bg-[#FFFFFF] flex text-base leading-[22px] items-center justify-between w-full p-5 font-semibold text-left text-[#122B36] rounded-tl-lg rounded-tr-lg"
        [attr.data-accordion-target]="'#accordion-collapse-body-' + (i + 1)"
        [attr.aria-expanded]="expandedItemIndex === i  ? 'true' : 'false'"
        [attr.aria-controls]="'accordion-collapse-body-' + (i + 1)"
        (click)="toggleAccordion(i)"
        [ngClass]="{'rounded-tl-lg': i === 0, 'rounded-tr-lg': i === 0}">
        <span>{{ item.title }}</span>
        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
        </svg>
      </button>
    </h2>
    <div class="bg-[#F2F2F2]" id="accordion-collapse-body-{{i + 1}}" [class.hidden]="expandedItemIndex  !== i" [attr.aria-labelledby]="'accordion-collapse-heading-' + (i + 1)">
      <div class="pl-5 pr-5 h-[98px] flex flex-col justify-start content-center">
        <p class="flex items-center h-full text-[#122B36] font-normal text-base leading-[19.2px]">{{ item.content }}</p>
        <p class="flex items-center h-full text-[#122B36] font-normal text-base leading-[19.2px]">{{ item.estado }}</p>
      </div>
    </div>
  </ng-container>
</div>



