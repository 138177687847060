import {inject} from '@angular/core';
import {Router, CanActivateFn} from '@angular/router';
import {map} from "rxjs";
import {FirebaseService} from '@shared/services/firebase.service'
import { ROUTES_APP } from 'src/app/app.routes';
import { environment } from '@environments/environment';
import { RoleName } from '@shared/const/role.sho';
import { SessionService } from '@shared/services/session.service';

export const UserHasRoleForPorfile: CanActivateFn = () => {
  const router = inject(Router);
  const firebaseService = inject(FirebaseService);
  const sessionService = inject(SessionService);
  const urlUnauthorized = router.createUrlTree([`/${environment.endpoints.shop}/${sessionService.getSessionLocalStoreShopId()}/${ROUTES_APP.PRODUCT}`]);

  // Obteniendo el rol del usuario desde la sesión o FirebaseService
  const userRole = firebaseService.setCustomClaimsRole();
  //console.log("Rol del usuario:", userRole);

  // Redirige a otra página si el rol es SHOP_MANAGER
  if (userRole === RoleName.SHOP_EXECUTIVE) { //Ejecutivo Tienda
    return urlUnauthorized;
  }

  if (userRole === RoleName.SOHO_ADMIN) { //Súper Admin Soho
    return true;
  }

  // Permitir la navegación
  return true;
};

export const UserHasRoleForRestUrl: CanActivateFn = () => {
  const router = inject(Router);
  const firebaseService = inject(FirebaseService);
  const sessionService = inject(SessionService);
  const urlProfile = router.createUrlTree([`/${environment.endpoints.shop}/${sessionService.getSessionLocalStoreShopId()}/${ROUTES_APP.PROFILE}`]);

  // Obteniendo el rol del usuario FirebaseService
  const userRole = firebaseService.setCustomClaimsRole();
  //console.log("Rol del usuario resto de rutas:", userRole);

  // Redirige a otra página
  if (userRole === RoleName.SOHO_ADMIN) { //Súper Admin Soho
    return urlProfile;
  }

  // Permitir la navegación
  return true;
};

export const UserIsLoggedIn: CanActivateFn = () => {
  const urlLogin = inject(Router).createUrlTree([`/${ROUTES_APP.SIGNIN}`])
  return inject(FirebaseService).isAuthenticatedUser()
      .pipe(map(isLogin => isLogin || urlLogin))
}

export const UserIsNotLoggedIn: CanActivateFn = () => {
  const urlNav = inject(Router).createUrlTree([`/${environment.endpoints.shop}/${sessionStorage.getItem('shop')}/${ROUTES_APP.PRODUCT}`])
  return inject(FirebaseService).isAuthenticatedUser()
      .pipe(map(isLogin => isLogin ? urlNav : true))
}
