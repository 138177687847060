<!-- boton filtro -->
<div  class="bg-white flex justify-between items-center cursor-pointer w-full h-[48px] border-[#122B36] border-2 rounded-lg"
      #btnDropdownRef
      (click)="toggleDropdown($event)" >
      <div class="flex items-center ml-3">
        <img
          class="w-[20px] h-[20px]" src="../../../../../../../assets/svg/icon-filter.svg"
          *ngIf="isIconFilter()">
        <img
          class="w-[20px] h-[20px]" src="../../../../../../../assets/svg/icon-order.svg"
          *ngIf="!isIconFilter()">
        <p class="ml-1 text-[#122B36] text-base font-medium">{{title}}</p>
      </div>
      <img *ngIf="!dropdownPopoverShow"
            class="w-[20px] h-[20px] mr-3" src="../../../../../../../assets/svg/icon-row-up.svg" >
      <img *ngIf="dropdownPopoverShow"
            class="w-[20px] h-[20px] mr-3" src="../../../../../../../assets/svg/icon-row-down.svg" >
</div>
<!-- dropdown -->
<div class="z-50"  #popoverDropdownRef>
  <div  class="xl:ml-[-100px] bg-white w-[297px] text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1"
        [ngClass]="dropdownPopoverShow ? 'block' : 'hidden'">
    <!-- items -->
    <ng-container *ngFor="let item of itemsGroup; let i = index">
      <div class="flex flex-col mt-2 ml-6 mr-3">
        <sho-item-group-shared [itemGroup]="item"></sho-item-group-shared>
      </div>
      <!-- separador -->
      <div *ngIf="drawSeparator(i)" class="h-0 my-3 border border-solid border-t-0 border-slate-800 opacity-25"></div>
    </ng-container>
    <!-- filter button-->

      <!-- <mat-card class="demo-inline-calendar-card">
        <mat-calendar style="width: 100%;"></mat-calendar>
      </mat-card> -->

    <div *ngIf="showButon" class="w-full flex justify-center my-5">
      <app-btn-perfil typeBtn="submit" color="success" [styles]="{height:'50px', width:'172px', background: '#146D81'}">
        Aplicar filtros
      </app-btn-perfil>
    </div>
  </div>
</div>
