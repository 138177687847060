<form novalidate class="space-y-4" (ngSubmit)="confirmDelete()">
  <div class="pt-[40px] pb-[24px] px-[20px] bg-gray-100 relative rounded-xl shadow">
    <div class="flex space-x-2 mb-5 flex-col justify-center items-center">
      <fa-icon class="text-2xl" [icon]="faCircleIcon"
                 [ngClass]="{'text-[#0CA348;]':isSuccessColor(), 'text-[#F54748;]':!isSuccessColor()}"></fa-icon>
      <h3 class="text-lg font-bold text-[#122B36] text-center">{{settings.tittle}}</h3>
    </div>
    <div class="flex flex-col w-full justify-center items-center">

    </div>
    <div class="flex w-full mt-[30px] justify-center">
      <div class="flex justify-between w-[389px]">
        <app-btn-perfil (click)="close()" class="w-[185px]" color="secondary">Cancelar</app-btn-perfil>
        <app-btn-perfil class="w-[185px] " typeBtn="submit" [loading]="status === 'loading'" color="confirm">Confirmar</app-btn-perfil>
      </div>
    </div>
    <div id="captcha_turnstile" class="flex items-center justify-center sticky bottom-2 mt-5"></div>
  </div>
</form>
