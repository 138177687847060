import { Component } from '@angular/core';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { ModalInterface } from '@shared/interfaces/modal.interface';
import { ToastService } from '@shared/services/toast.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'sho-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent {

  mostrarErrorMsg=true;
  faCircle = faCircleCheck;
  modal$: Observable<ModalInterface>

  constructor(private modal: ToastService) {
    this.modal$ = modal.modal$
  }

  getAlertDisplay(type: string | undefined) {
    switch (type) {
      case "WARNING":
        return 'warning'
      case "ERROR":
        return 'cancel'
      case "SUCCESS":
        return 'done'
      default:
        return 'cancel'
    }
  }

}
