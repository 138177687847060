<div class="flex">
  <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar"
          aria-controls="logo-sidebar" type="button"
          class="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500  lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200">
    <span class="sr-only">Open sidebar</span>
    <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path clip-rule="evenodd" fill-rule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
    </svg>
  </button>
  <div class="w-full hidden justify-center mt-2">
    <a [routerLink]="getUrl('dashboard')" class="lg:hidden flex items-center w-40 mr-14">
      <img title="Logo" src="./../../../../assets/images/Logo soho Seller.png" class="logoClase"
           alt="soho Logo"/>
    </a>
  </div>
</div>

<aside id="logo-sidebar"
       class="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full lg:translate-x-0"
       aria-label="Sidebar">
  <div class="w-[230px] h-full overflow-y-auto bg-white dark:bg-gray-800 flex flex-col">
    <div class="flex-grow flex justify-end" [ngClass]="{'hidden': currentSlideMenu, '': !currentSlideMenu}">
      <!-- <button data-drawer-target="drawer-navigation" (click)="abrirMenu()" type="button" data-drawer-hide="drawer-navigation" aria-controls="drawer-navigation"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
             xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
        </svg>
        <span id="drawer-navigation" class="sr-only">Close menu</span>
      </button> -->
    </div>

    <a [routerLink]="getUrl('product')" class="flex items-center w-full p-7">
      <img src="./../../../../assets/images/Logo soho Seller.png" class="logoClase"
           alt="soho Logo"/>
    </a>
    <div class="flex items-center w-full p-7">
      <p class="font-lato font-bold leading-6 text-center w-full">{{getRoleName}}</p>
    </div>
    <ul class="space-y-2 font-medium">

      <li [shoRole]="[roleList.SOHO_EXECUTIVE,roleList.SHOP_ADMIN, roleList.SOHO_ADMIN]">
        <a routerLinkActive="active-link" [routerLink]="getUrl('profile')"
           class="newHeigth flex items-center w-full p-2 text-base text-gray-900 transition duration-75 group hover:bg-gray-100">
          <svg routerLinkActive="active-linkSvg" [routerLink]="getUrl('profile')" class="w-5 h-5 text-gray-800"
               aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 14 18">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M7 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-2 3h4a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z"/>
          </svg>
          <span routerLinkActive="active-linkSvg"
                class="flex-1 ml-3 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Usuarios</span>
        </a>
      </li>

      <li [shoRole]="[roleList.SHOP_EXECUTIVE,roleList.SOHO_EXECUTIVE,roleList.SHOP_ADMIN]">
        <a routerLinkActive="active-link" [routerLink]="getUrl(routes.SHOPS)"
           class="newHeigth flex items-center p-2 text-gray-900 hover:bg-gray-100 group">
            <svg class="iconsFill w-5 h-5" routerLinkActive="active-linkSvg" [routerLink]="getUrl(routes.SHOPS)"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="#000000"><path d="M816-504v287.64q0 29.85-21.15 51.1Q773.7-144 744-144H216q-29.7 0-50.85-21.5Q144-187 144-216v-289q-33-23-41.5-59t.5-76l46-128q5-24 23-36t43.67-12h528.66q25.67 0 43.17 11t23.5 37l46 128q9 40 0 76t-41 60Zm-248-48q21 0 35.5-14t12.5-34l-24-144h-72v143.62Q520-580 534-566q14 14 34 14Zm-176.5 0q20.5 0 34.5-14t14-34.38V-744h-72l-24 144q-2 20 12.5 34t35 14ZM216-552q18 0 31.5-11t15.5-28l25-153h-72l-45 128q-8 23 6 43.5t39 20.5Zm528 0q25 0 39.5-20.5T790-616l-46-128h-72l25 153q2 17 16 28t31 11ZM216-216h528v-264q-25 0-47.5-9.5T656-519q-18 20-40.36 29.5T568-480q-25.18 0-47.09-10Q499-500 480-519q-17 19-40 29t-48 10q-25 0-47-8.5T304-519q-22.02 21.55-43.51 30.28Q239-480 216-480v264Zm528 0H216h.5-.71 528.28H743h1Z"/></svg>

          <span routerLinkActive="active-linkSvg"
                class="flex-1 ml-3 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Tiendas</span>
        </a>
      </li>

      <li [shoRole]="[roleList.SHOP_EXECUTIVE,roleList.SOHO_EXECUTIVE,roleList.SHOP_ADMIN]">
        <a [ngClass]="{'active-link-father': isActiveRoute([routes.PRODUCT, routes.PENDING])}"
           id="productos-id-drop"
           aria-controls="dropdown-product"
           data-collapse-toggle="dropdown-product"
           class="newHeigth flex items-center p-2 text-gray-900 hover:bg-gray-100 group cursor-pointer">
          <svg routerLinkActive="active-linkSvg" class="w-5 h-5 text-gray-800" aria-hidden="true"
               xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 18 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M12 9V4a3 3 0 0 0-6 0v5m9.92 10H2.08a1 1 0 0 1-1-1.077L2 6h14l.917 11.923A1 1 0 0 1 15.92 19Z"/>
          </svg>
          <span routerLinkActive="active-linkSvg"
                class="flex-1 ml-3 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Productos</span>
          <svg *ngIf="!isActiveRoute([routes.PRODUCT, routes.PENDING])" class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 4 4 4-4"/>
          </svg>
          <svg *ngIf="isActiveRoute([routes.PRODUCT, routes.PENDING])" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 12.5L10.1772 7.67715C10.0793 7.5793 9.92067 7.5793 9.82283 7.67715L5 12.5" stroke="#146D81" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <ul id="dropdown-product" class="hidden">
          <li>
            <a routerLinkActive="active-link-child"
               [routerLink]="getUrl(routes.PRODUCT)"
               class="newHeigth flex items-center text-gray-900 hover:bg-gray-100 group">
              <span routerLinkActive="active-linkSvg"
                    class="flex-1 ml-10 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Catálogo</span>
            </a>
          </li>
          <li>
            <a routerLinkActive="active-link-child"
               [routerLink]="getUrl(routes.PENDING)"
               class="newHeigth flex items-center text-gray-900 hover:bg-gray-100 group">
              <span routerLinkActive="active-linkSvg"
                    class="flex-1 ml-10 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Pendientes</span>
            </a>
          </li>
        </ul>
      </li>

      <li [shoRole]="[roleList.SHOP_EXECUTIVE,roleList.SOHO_EXECUTIVE,roleList.SHOP_ADMIN]">
        <a [ngClass]="{'active-link-father': isActiveRoute([routes.ORDERS, routes.RETURNS, routes.SHIPMENTS])}" aria-controls="multiple-level" data-collapse-toggle="multiple-level"
           class="newHeigth flex items-center p-2 text-gray-900 hover:bg-gray-100 group cursor-pointer">
          <svg class="w-5 h-5 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 18 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M6 15a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0h8m-8 0-1-4m9 4a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-9-4h10l2-7H3m2 7L3 4m0 0-.792-3H1"/>
          </svg>
          <span routerLinkActive="active-linkSvg" class="flex-1 ml-3 text-left whitespace-nowrap">Ventas</span>
          <svg *ngIf="!isActiveRoute([routes.ORDERS, routes.RETURNS, routes.SHIPMENTS])" class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 4 4 4-4"/>
          </svg>
          <svg *ngIf="isActiveRoute([routes.ORDERS, routes.RETURNS, routes.SHIPMENTS])" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 12.5L10.1772 7.67715C10.0793 7.5793 9.92067 7.5793 9.82283 7.67715L5 12.5" stroke="#146D81" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

        </a>
        <ul id="multiple-level" class="hidden">
          <li>
            <a routerLinkActive="active-link-child" [routerLink]="getUrl(routes.ORDERS)"
               class="newHeigth flex items-center text-gray-900 hover:bg-gray-100 group">
              <span routerLinkActive="active-linkSvg"
                    class="estiloSpan flex-1 ml-10 font-normal aling leading-[19.2px] text-base text-[#122B36] h-[19px]">Ventas</span>
            </a>
          </li>
          <li>
            <a routerLinkActive="active-link-child" [routerLink]="getUrl(routes.SHIPMENTS)"
               class="newHeigth flex items-center text-gray-900 hover:bg-gray-100 group">
              <span routerLinkActive="active-linkSvg"
                    class="estiloSpan flex-1 ml-10 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Envíos</span>
            </a>
          </li>
          <li>
            <a routerLinkActive="active-link-child" [routerLink]="getUrl(routes.RETURNS)"
               class="newHeigth flex items-center text-gray-900 hover:bg-gray-100 group">
              <span routerLinkActive="active-linkSvg"
                    class="flex-1 ml-10 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Solicitudes</span>
            </a>
          </li>
        </ul>
      </li>

      <li [shoRole]="[roleList.SHOP_EXECUTIVE,roleList.SOHO_EXECUTIVE,roleList.SHOP_ADMIN]">
        <a routerLinkActive="active-link" [routerLink]="getUrl(routes.OFERTAS)"
           class="newHeigth flex items-center p-2 text-gray-900 hover:bg-gray-100 group">
          <svg routerLinkActive="active-linkSvg" [routerLink]="getUrl('ofertas')" class="w-5 h-5 text-gray-800 icons"
               aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 19 18">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"
                  d="M13.583 5.445h.01M8.86 16.71l-6.573-6.63a.993.993 0 0 1 0-1.4l7.329-7.394A.98.98 0 0 1 10.31 1l5.734.007A1.968 1.968 0 0 1 18 2.983v5.5a.994.994 0 0 1-.316.727l-7.439 7.5a.975.975 0 0 1-1.385.001Z"/>
          </svg>
          <span routerLinkActive="active-linkSvg"
                class="flex-1 ml-3 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Ofertas</span>
        </a>
      </li>

      <li [shoRole]="[roleList.SHOP_EXECUTIVE,roleList.SOHO_EXECUTIVE,roleList.SHOP_ADMIN]">
        <a routerLinkActive="active-link" [routerLink]="getUrl(routes.MENSAJES)"
           class="newHeigth flex items-center p-2 text-gray-900 hover:bg-gray-100 group">
          <svg routerLinkActive="active-linkSvg" [routerLink]="getUrl('mensajes')" class="w-5 h-5 text-gray-800 icons"
               aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 20 18">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"
                  d="M5.5 6.5h.01m4.49 0h.01m4.49 0h.01M18 1H2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v5l5-5h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"/>
          </svg>
          <span routerLinkActive="active-linkSvg"
                class="flex-1 ml-3 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Mensajes</span>
        </a>
      </li>

      <li [shoRole]="[roleList.SHOP_EXECUTIVE,roleList.SOHO_EXECUTIVE,roleList.SHOP_ADMIN]">
        <a routerLinkActive="active-link" [routerLink]="getUrl(routes.ESTADISTICAS)"
           class="newHeigth flex items-center p-2 text-gray-900 hover:bg-gray-100 group">
          <svg routerLinkActive="active-linkSvg" [routerLink]="getUrl(routes.ESTADISTICAS)"
               xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"/>
          </svg>
          <span routerLinkActive="active-linkSvg"
                class="flex-1 ml-3 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Estadísticas</span>
        </a>
      </li>

      <li [shoRole]="[roleList.SHOP_EXECUTIVE,roleList.SOHO_EXECUTIVE,roleList.SHOP_ADMIN]">
        <a routerLinkActive="active-link" [routerLink]="getUrl(routes.LIQUIDATIONS)"
           class="newHeigth flex items-center p-2 text-gray-900 hover:bg-gray-100 group">
          <svg class="iconsFill" routerLinkActive="active-linkSvg" [routerLink]="getUrl(routes.LIQUIDATIONS)"
          xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#000000"><path d="M446-216h67v-47q49-8 81-42t32-79q0-45-27.5-77T514-514q-61-22-80.5-37.5T414-592q0-20 17.5-33t45.5-13q28 0 49 13.5t28 36.5l59-25q-12-33-38.5-55.5T513-697v-47h-66v48q-45 10-72 38.5T348-591q0 45 30.5 76.5T475-460q45 16 65.5 34t20.5 42q0 26-21 43.5T488-323q-33 0-58.5-22T395-402l-62 26q12 42 42 71.5t71 40.5v48Zm34 120q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Zm0-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>
          <span routerLinkActive="active-linkSvg"
                class="flex-1 ml-3 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Liquidaciones</span>
        </a>
      </li>

      <li [shoRole]="[roleList.SHOP_EXECUTIVE,roleList.SOHO_EXECUTIVE,roleList.SHOP_ADMIN]">
        <a routerLinkActive="active-link" [routerLink]="getUrl(routes.MULTIVENDE)"
           class="newHeigth mb-20 flex items-center p-2 text-gray-900 hover:bg-gray-100 group">
          <svg routerLinkActive="active-linkSvg" [routerLink]="getUrl(routes.MULTIVENDE)"
           class="text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.143 4H4.857A.857.857 0 0 0 4 4.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 10 9.143V4.857A.857.857 0 0 0 9.143 4Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 20 9.143V4.857A.857.857 0 0 0 19.143 4Zm-10 10H4.857a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286a.857.857 0 0 0 .857-.857v-4.286A.857.857 0 0 0 9.143 14Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286a.857.857 0 0 0 .857-.857v-4.286a.857.857 0 0 0-.857-.857Z"/>
          </svg>


          <span routerLinkActive="active-linkSvg"
                class="flex-1 ml-3 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Multivende</span>
        </a>
      </li>
    </ul>

    <div class="mt-auto cursor-pointer">
      <a  (click)="logout()"
           class="newHeigth flex items-center p-2 text-gray-900 hover:bg-gray-100 group">
           <img src="./../../../../../assets/svg/Logout.svg" class="" alt="soho Logo"/>
           <span class="flex-1 ml-3 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Cerrar sesión</span>
        </a>
    </div>
  </div>
</aside>
