import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { getAuth, provideAuth } from "@angular/fire/auth";
import { getApp, initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { getStorage, provideStorage } from "@angular/fire/storage";
import { provideAnalytics, getAnalytics } from "@angular/fire/analytics";
import { getFirestore, provideFirestore } from "@angular/fire/firestore";
import { FirebaseService } from "./shared/services/firebase.service";
import { from, Observable } from "rxjs";
import { NotFoundComponent } from './views/not-found/not-found.component';
import { AppComponent } from './app.component';
import { SharedModule } from '@shared/shared.module';
import { Interceptor } from '@shared/interceptors/token.interceptor'
import { environment } from '@environments/environment'
import { ToastComponent } from '@shared/components/toast/toast.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogModule } from "@angular/cdk/dialog";
import {DatePipe, DecimalPipe, PercentPipe, TitleCasePipe} from "@angular/common";

function initializeAppFactory(firebaseService: FirebaseService): () => Observable<any> {
  return () => from(firebaseService.initApp());
}


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    ToastComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    FontAwesomeModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth(getApp())),
    provideFirestore(() => getFirestore(getApp())),
    provideStorage(() => getStorage(getApp())),
    provideAnalytics(() => getAnalytics(getApp())),
    BrowserAnimationsModule,
    DialogModule,
  ],
  providers: [
    DatePipe, DecimalPipe, PercentPipe, TitleCasePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [FirebaseService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
