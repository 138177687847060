import {Component, inject, OnInit, HostListener} from '@angular/core';
import {FirebaseService} from "@shared/services/firebase.service";
import {Router} from '@angular/router';
import {SessionService} from "@shared/services/session.service";
import {ROUTES_APP} from "../../../../app.routes";
import {initFlowbite} from "flowbite";
import {NameOfRole, RoleName, RoleSho} from "@shared/const/role.sho";
import { environment } from '@environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    const isLGScreen = window.innerWidth >= 1024;

    if (isLGScreen) {
      this.currentSlideMenu = true
    }
  }

  currentSlide = true
  currentSlideMenu = true

  private _firebase = inject(FirebaseService)
  private router = inject(Router)
  private _sessionService = inject(SessionService)

  get routes() {
    return ROUTES_APP
  }

  get getRoleName() {
    return this._getNameByRole(this._sessionService.getRoleStore())
  }

  get roleList() {
    return RoleName
  }

  ngOnInit() {
    initFlowbite()
  }

  abrirMenu() {
    this.currentSlideMenu = !this.currentSlideMenu
  }

  async logout() {
    await this._firebase.doLogout();

    //al cerrar sesion quitar el backdrop que se pone de color gris.
    let backdropElement = document.querySelector('div[drawer-backdrop]');
    if(backdropElement) backdropElement.remove();

    this.router.navigateByUrl(`/${ROUTES_APP.SIGNIN}`).then()

  }

  public getUrl(url: string) {
    return `/${environment.endpoints.shop}/${this._sessionService.getShopIdStore()}/${url}`
  }

  public isActiveRoute(urls: string[]): boolean {
    return urls.some(url => this.router.url.split(this._sessionService.getShopIdStore())[1].split('/')[1] === url )
  }

  private _getNameByRole(role: string): string {
    return NameOfRole[role as RoleSho]
  }

}
