<button *ngIf="items?.length" mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
        aria-label="Example icon-button with a menu">
  <mat-icon>{{menuTrigger.menuOpen ? 'close' : 'menu'}}</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <div class="menu-custom">
    <ng-container *ngFor="let item of items">
      <button
        (click)="selected(item.id)"
        mat-menu-item
        disabled="{{item.disable}}">
        <div class="flex">
          <img class="mr-3" [src]="item.icon">
          <span>{{item.name}}</span>
        </div>
      </button>
    </ng-container>
  </div>
</mat-menu>
