import { inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map, Observable, of, tap } from "rxjs";
import { shopList } from "@shared/components/modal-shop-selector/modal-shop-selector.component";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  private shops: shopList[] = []

  protected http = inject(HttpClient)

  getShops(force: boolean = false): Observable<shopList[]> {
    return (this.shops.length && !force) ?
      of(this.shops) :
      this.http.get<{ success: boolean, data: shopList[] }>(this._getShopsUrl()).pipe(
        map(res => res.data),
        tap(res => { this.shops = res })
      )
  }

  /* getShops(force: boolean = false): Observable<shopList[]> {
    // Intenta obtener los datos almacenados en localStorage
    const storedShops = localStorage.getItem('shopLists');

    // Si hay datos en localStorage y no se fuerza la recarga, úsalo
    if (storedShops) {
      try {
        const parsedShops = JSON.parse(storedShops); // Convierte la cadena en un objeto/arreglo
        this.shops = parsedShops; // Actualiza this.shops
        return of(this.shops); // Devuelve los datos como un Observable
      } catch (error) {
        console.error('Error al parsear los datos de localStorage:', error);
        // Si ocurre un error al parsear, forzamos la recarga desde el servidor
      }
    }

    // Si no hay datos válidos en localStorage o se fuerza la recarga, realiza el llamado HTTP
    return this.http.get<{ success: boolean, data: shopList[] }>(this._getShopsUrl()).pipe(
      map(res => res.data),
      tap(res => {
        this.shops = res; // Actualiza this.shops
        localStorage.setItem('shopLists', JSON.stringify(res)); // Guarda los datos en localStorage
      })
    );
  } */


  private _getShopsUrl(): string {
    return `${environment.baseURL}${environment.endpoints.stores}`
  }
}
