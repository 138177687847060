import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { Dialog } from '@angular/cdk/dialog';

import { Product, ProductVariant } from '@shared/interfaces/productEcommerce.model';
import { ModalGenericComponent } from '../modal-generic/modal-generic.component';
import { ModalIcon, ModalSettings } from '../modal-generic/modal-model';
import { ModalAttributesEcommerceComponent } from '../modal-attributes-ecommerce/modal-attributes-ecommerce.component';

interface ExampleFlatNode {
  expandable: boolean;
  id: string;
  description: string;
  productType: string;
  tags: string[];
  title: string;
  totalInventory: number;
  vendor: string;
  variants: [];
  level: number;
  sku: string;
  price: string;
  idPadre: string;
  selectedOptions: [];
  seleccionado: boolean
}

@Component({
  selector: 'sho-table-tree-ecommerce',
  templateUrl: './table-tree-ecommerce.component.html',
  styleUrls: ['./table-tree-ecommerce.component.scss'],
})
export class TableTreeEcommerceComponent implements OnChanges {

  constructor(
    public dialog: Dialog
  ){

  }

  @Input() productosSeleccionados: Product[] = [];
  @Input() treeData: Product[] = [];
  @Output() selectionChange = new EventEmitter<Product[]>();
  @Output() seleccionadoTodo = new EventEmitter<boolean>();

  displayedColumns: string[] = [
    'select',
    'name',
    'sku',
    'precio',
    'Atributos'
  ];
  selection = new SelectionModel<Product>(true, []);

  private transformer = (node: Product, level: number) => {
    return {
      expandable: !!node.variants && node.variants.length > 0,
      id: node.id,
      description: node.description,
      productType: node.productType,
      tags: node.tags,
      title: node.title,
      totalInventory: node.totalInventory,
      vendor: node.vendor,
      variants: node.variants,
      sku: node.sku,
      price: node.price,
      idPadre: node.idPadre,
      selectedOptions: node.selectedOptions,
      level: level,
      seleccionado: false,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.variants
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  ngOnChanges(changes: SimpleChanges) {
    //console.log(this.productosSeleccionados)
    if (changes['treeData']) {
      this.dataSource.data = this.treeData;

    }
    this.markSelectedProducts()
    //console.log(this.dataSource.data)
  }

  markSelectedProducts() {
    this.treeControl.dataNodes.forEach((node) => {
      //console.log(node)
      node.seleccionado = this.productosSeleccionados.some((producto) => producto.id === node.id)
                          || this.productosSeleccionados.some((producto) => producto.idPadre === node.idPadre);
      //console.log(node.seleccionado)
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numTotalNodes = this.treeControl.dataNodes.length;
    const numProductNodes = this.treeControl.dataNodes.filter(node => node.level === 0).length;
    return numSelected === numTotalNodes - numProductNodes;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      // Si ya están seleccionados todos los nodos, deseleccionar todos los nodos
      this.selection.clear();
    } else {
      // Si no todos los nodos están seleccionados, seleccionar todos los nodos, excepto los del nivel 0 (productos)
      this.selection.select(...this.treeControl.dataNodes.filter(node => node.level > 0));
    }

    this.selectionChange.emit(this.selection.selected);
    this.seleccionadoTodo.emit(this.isAllSelected());
  }

  toggleSubproducts(node: ExampleFlatNode) {
    // Obtener todos los descendientes del nodo
    const descendants = this.treeControl.getDescendants(node);

    if (descendants.length === 0) {
      // Si el nodo no tiene descendientes, simplemente alternar su selección
      this.selection.toggle(node);
    } else {
      // Si el nodo tiene descendientes, determinar el estado de la selección
      const allSelected = descendants.every((child) =>
        this.selection.isSelected(child)
      );
      if (allSelected) {
        // Si todos los descendientes están seleccionados, deseleccionarlos
        this.selection.deselect(...descendants);
      } else {
        // Si no todos los descendientes están seleccionados, seleccionarlos
        this.selection.select(...descendants);
      }
    }

    // Emitir el evento `selectionChange` con los elementos seleccionados
    this.selectionChange.emit(this.selection.selected);
    this.seleccionadoTodo.emit(this.isAllSelected());
  }

  allSelectedTree(node: ExampleFlatNode) {
    //console.log(node)
    const descendants = this.treeControl.getDescendants(node);

    if (!descendants.length) {
      return this.selection.isSelected(node);
    }

    return descendants.every((child) => this.selection.isSelected(child));
  }

  indeterminateTree(node: ExampleFlatNode) {
    const descendants = this.treeControl.getDescendants(node);

    if (!descendants.length) {
      return false;
    }

    const desct = descendants.every((child) =>
      this.selection.isSelected(child)
    );

    const desctSell = descendants.some((child) =>
      this.selection.isSelected(child)
    );

    return !desct && desctSell;
  }

  checkboxLabel(row?: Product): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }


  openDialog(atributos: any) {
    //console.log(atributos)
    const settings:ModalSettings = {
      tittle:"Atributos",
      paragraph: atributos,
      paragraphSize:"w-[335px]",
      icon: undefined,
    }

    const dialogRef = this.dialog.open(ModalAttributesEcommerceComponent, {
      width: '400px',
      data: settings,
    });
  }

}



