import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { createPopper } from "@popperjs/core";
import { GroupType, IconDropdown, ItemGroup } from './dropdown-model';

@Component({
  selector: 'sho-select-dropdown-shared',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.css']
})
export class SelectDropdownComponent implements AfterViewInit {

  @Input() itemsGroup?:ItemGroup[];
  @Input() title?:string;
  @Input() icon?:IconDropdown=IconDropdown.Filter;
  @Input() showButon:boolean=true;

  dropdownPopoverShow = false;

  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef?: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef?: ElementRef;

  ngAfterViewInit() {
    createPopper(
      this.btnDropdownRef?.nativeElement,
      this.popoverDropdownRef?.nativeElement,
      {
        placement: "bottom-start",
      }
    );
  }

  toggleDropdown(event:any) {
    event.preventDefault();
    this.dropdownPopoverShow = !this.dropdownPopoverShow;
  }

  drawSeparator(index:number) : boolean {
    return !(this.itemsGroup?.length == ++index);
  }

  isIconFilter (): boolean {
    return this.icon == IconDropdown.Filter;
  }
}
