<form novalidate class="space-y-4" (ngSubmit)="realizarPago()">
  <div
    class="pt-[40px] pb-[24px] px-[20px] bg-gray-100 relative rounded-xl shadow"
  >
    <div class="flex space-x-2 mb-5 flex-col justify-center items-center">
      <img class="w-[34px] h-[34px]" src="../../../../../assets/svg/Info.svg" alt="">
      <h3 class="text-center text-lg font-bold text-[#122B36]">
        ¿Confirmas que deseas asignar como estrella estos 8 productos?
      </h3>
      <h4 class="mt-2 text-center text-base font-medium text-[#122B36] leading-[22px] h-[44px]">
        Estos productos se destacarán en la página principal de Soho Market hasta por 21 días, luego de eso podrás renovar la compra o eliminar como estrella a estos productos
      </h4>
      <p class="mt-12 text-center text-base font-medium text-[#122B36] leading-[22px] h-[44px]">
        Monto a pagar por los 8 productos estrella
      </p>
      <p class="mt-2 text-center text-base font-bold text-[#146D81] leading-[22px] h-[44px]">
        $28.990
      </p>
    </div>
    <div class="flex flex-col w-full justify-center items-center"></div>
    <div class="flex w-full mt-[30px] justify-center">
      <div class="flex justify-between w-[389px]">
        <app-btn-perfil (click)="close()" class="w-[185px]" color="secondary"
          >Cancelar</app-btn-perfil
        >
        <app-btn-perfil
          class="w-[185px]"
          typeBtn="submit"
          [loading]="status === 'loading'"
          color="confirm"
          >Realizar pago</app-btn-perfil
        >
      </div>
    </div>
  </div>
</form>
